import flatpickr from 'flatpickr';
import { Dutch } from 'flatpickr/dist/l10n/nl.js';
import RegexParser from 'regex-parser';
import * as DOMPurify from 'dompurify';

import { BotsSDK } from '../BotsSDK';
import { Features } from '../enums/features.enum';
import { UserType } from '../enums/user-type.enum';
import {
    BotResponseModel,
    IAttachmentData,
    IAuthorData,
    IChoiceData,
    IExpressionData,
    IKnowledgebaseData,
    IQuickReplyData,
} from '../models/bot-response.model';
import { EntityModel } from '../models/entity.model';
import { FieldModel, FormModel } from '../models/form.model';
import { IHandoverProps } from '../models/handoverProps.model';
import { StateModel } from '../models/state.model';
import { WidgetConfigModel } from '../models/widget-config.model';
import AlternativeReplyBox from '../util/alternative-replybox';
import { createForm } from '../util/createForm';
import { createElement } from '../util/dom-elements';
import FeatureToggle from '../util/feature-toggle';
import SessionStorage from '../util/sessionStorage';
import { getSvg } from '../util/svg';
import UI from '../util/ui';
import {
    createHtmlForFile,
    createPreviewForFile,
    debounce,
    escapeStringContent,
    getAvatar,
    getFormattedDisplayDate,
    getTime,
    replaceURLWithHTMLLinks,
} from '../util/util';

import { I18n } from "i18n-js";
import { translations } from "../util/translations";

export class WidgetBase {
    protected launcherIframe: HTMLIFrameElement;
    protected launcher: Document;
    protected widgetIframe: HTMLIFrameElement;
    protected widget: Document;
    protected delayedBlur: ReturnType<typeof setTimeout>;
    protected sdkInstance: BotsSDK;
    protected config: WidgetConfigModel;
    protected open = false;
    protected initialized = false;
    protected isTyping = false;
    protected featureToggle: FeatureToggle;
    protected ui: UI;
    protected alternativeReplyBox: AlternativeReplyBox;
    protected i18n: I18n;

    constructor(public sdk: BotsSDK, overrideConfig: Partial<WidgetConfigModel>) {
        this.sdkInstance = sdk;
        this.ui = new UI(sdk);
        this.sdkInstance.getConfig().then((config: WidgetConfigModel) => this.onConfigReveived(config, overrideConfig));
        this.sdkInstance.setOnNewMessage((msg: BotResponseModel) => this.onIncomingMessage(msg));
        this.sdkInstance.setOnPropRecieved((props: IHandoverProps) => this.onPropRecieved(props));
        this.sdkInstance.setOnTyping((typing: boolean) => this.onTyping(typing));
        this.sdkInstance.setSessionStarted((started: boolean) => this.sessionStarted(started));
        this.sdkInstance.setOnRequestPending((pending: boolean) => this.onRequestPending(pending));

        this.i18n = new I18n(translations);
    }

    public onConfigReveived(config: WidgetConfigModel, overrideConfig: Partial<WidgetConfigModel>) {
        if (config.hasOwnProperty("chatbot_id")) {
            this.config = { ...config, ...overrideConfig };
            this.featureToggle = new FeatureToggle(this.config.tf);
            this.ui.setConfig(this.config);
            if (this.config.custom_css) {
                this.ui.addStylesheet(`${process.env.BASE_URL}/custom.css`, window.document, () => {
                    this.ui.addStylesheet(this.config.custom_css, window.document, () => {
                        this.CheckWhitelisting(config);
                        this.CheckInitialState(config);
                    }, () => {
                        console.warn(`"${this.config.custom_css}" - custom css file not found`);
                        this.CheckWhitelisting(config);
                        this.CheckInitialState(config);
                    });
                }, () => {
                    console.error("Unable to load - custom css not found");
                });
            } else {
                this.CheckWhitelisting(config);
                this.CheckInitialState(config);
            }
        } else {
            console.error("Unable to load - no chatbot id");
        }
    }

    public CheckInitialState(config: WidgetConfigModel) {
        this.i18n.locale = config.nlu_language_code;
        if (config[this.ui.isMobile() ? "m_enable_launcher" : "enable_launcher"]) {
            this.createLauncherIframe();
        } else {
            this.handleOpenState();
        }
    }

    public CheckWhitelisting(config: WidgetConfigModel) {
        if (!!config.whitelisted_urls && !!config.whitelisted_urls.length) {
            const isWhitelisted = config.whitelisted_urls.some((url) => RegexParser(url).test(window.location.href));
            if (!isWhitelisted) {
                console.warn("This is not a whitelisted domain, you can not proceed");
                return;
            }
        }
    }

    public initWidget() {
        this.createWidgetIframe();
        this.onStartSession();
        this.initialized = true;
    }

    public toggleWidgetFrame() {
        this._toggleWidgetFrame();
    }

    public get isInitialized() {
        return this.initialized;
    }

    protected getAvatar(type: string, config: WidgetConfigModel, author?: IAuthorData) {
        return getAvatar(type, config, author);
    }

    protected onTyping(typing: boolean) {
        this.widget.getElementById("typing").classList.toggle("hidden", !typing);
    }

    protected sessionStarted(started: boolean) {
        this.onRequestPending(!started);
    }

    protected onRequestPending(pending: boolean) {
        if (this.featureToggle.check(Features.RequestPending)) {
            this.toggleReplyBox(!pending);
            this.togglePending(pending)
        }
    }

    protected togglePending(pending: boolean) {
        let pendingElement: HTMLElement;
        const widget = this.widget.getElementById("widget");
        if (pending) {
            pendingElement = createElement(document, "div", "pending");
            pendingElement.innerHTML = getSvg("spinner");
            const replybox = this.widget.getElementById("replyBox");
            replybox.appendChild(pendingElement)
            pendingElement.setAttribute("tabindex", "0");
            pendingElement.focus();
        } else {
            pendingElement = widget.querySelector("div.pending");
            if (pendingElement) {
                pendingElement.parentElement.removeChild(pendingElement);
            }
            this.setFocusOnMessageField();
        }
    }

    protected onStartSession() {
        this.sdkInstance.startSession(this.config.chatbot_id);
    }

    protected onUserStopTyping() {
        this.isTyping = false;
        this.sdkInstance.sendTyping(false);
    }

    protected onUserStartTyping() {
        if (!this.isTyping) {
            this.isTyping = true;
            this.sdkInstance.sendTyping(true);
        }
    }

    protected onSendMessage(response: BotResponseModel, payload: {} = {}) {
        const message = <IExpressionData>response.data;
        this.widget.dispatchEvent(new CustomEvent("messageSend"));
        this.sdkInstance.sendMessage(message.reply, payload);
        if (message.reply) this.addExpressionToList(response);
    }

    protected onPropRecieved(props: IHandoverProps) {
        props.canSendAttachment ? this.createAttachementButton() : this.removeAttachementButton();
        if (this.featureToggle.check(Features.ConnectionPending) && props.hasOwnProperty("connected")) {
            this.toggleReplyBox(props.connected);
            this.widget.getElementById("connection-pending").classList.toggle("hidden", props.connected)
        }
    }

    protected onSendAttachement(event: Event) {
        this.sdkInstance.sendAttachement(event);
    }

    protected onIncomingMessage(message: BotResponseModel, toStorage=true) {
        switch (message.type) {
            case "expression":
            case "user":
            case "rep":
                this.addExpressionToList(message);
                break;
            case "knowledgebase":
                this.addKnowledgeItemToList(message);
                break;
            case "choices":
                this.addChoices(message);
                break;
            case "attachment":
                this.addAttachmentToList(message);
                break;
            case "completion":
                this.sdkInstance.sessionConplete();
                break;
        }
    }

    protected createLauncherIframe() {
        const iframe = createElement(document, "iframe", [], this.sdkInstance.widgetId || "") as HTMLIFrameElement;
        iframe.title = this.i18n.t("launcherIframeTitle");
        iframe.name = "OBIBots_launcher";
        iframe.setAttribute("frameBorder", "0");
        iframe.setAttribute("data-custom", "OBIBots_launcher");
        iframe.setAttribute("scrolling", "no");
        this.ui.addStyle(iframe, 'position', "fixed");
        this.ui.addStyle(iframe, 'bottom', "16px");
        this.ui.addStyle(iframe, 'right', "16px");
        this.ui.addStyle(iframe, 'width', "64px");
        this.ui.addStyle(iframe, 'height', "64px");
        this.ui.addStyle(iframe, 'z-index', "9999");
        iframe.addEventListener("load", () => this.launcherIframeOnLoad(iframe));
        document.body.appendChild(iframe);
    }

    protected launcherIframeOnLoad(iframe: HTMLIFrameElement) {
        this.launcherIframe = iframe;
        this.launcher = iframe.contentDocument;
        if (this.config.custom_css) {
            this.ui.addStylesheet(`${process.env.BASE_URL}/style.css`, this.launcher, () => {
                this.ui.addStylesheet(`${process.env.BASE_URL}/custom.css`, this.launcher, () => {
                    this.ui.addStylesheet(this.config.custom_css, this.launcher, () => {
                        this.launcherStylesLoaded();
                    }, () => {
                        console.warn(`Launcher - "${this.config.custom_css}" - custom css file not found`);
                        this.launcherStylesLoaded();
                    });
                }, () => {
                    console.error("Launcher - custom css not found");
                });
            }, () => {
                console.error("Launcher - style css not found");
            });
        } else {
            this.ui.addStylesnode(require("../styles/style.css").toString(), this.launcher);
            this.launcherStylesLoaded();
        }
    }

    protected launcherStylesLoaded() {
        this.launcher.body.innerHTML = require("../templates/launcher.html").toString();
        this.ui.addStylesnode(`
            #launcherButton {
                color: ${this.config.launcher_color};
                border-color: ${this.config.launcher_color};
                background-color: ${this.config.launcher_color};
                fill: ${this.config.launcher_icon_color};
            }`, this.launcher);
        const title = document.createElement("title");
        title.innerText = this.i18n.t("launcherIframeTitle");
        this.launcher.head.appendChild(title);
        this.launcher.getElementById("launcherButton").title = this.i18n.t("openTheChatbot");
        this.launcher.getElementById("launcherButton").addEventListener("click", (event: MouseEvent) => this._toggleWidget(event));
        this.handleOpenState();
    }

    protected handleOpenState() {
        const state: StateModel = JSON.parse(SessionStorage.get(this.config.chatbot_id));
        const openByDefault = this.config[this.ui.isMobile() ? "m_open_by_default" : "open_by_default"];
        if (state?.isOpen || (!state && openByDefault)) {
            this.initWidget();
            this._toggleWidget(new MouseEvent("click"));
        } else if (state?.isOpen === false) {
            this.initWidget();
        } else {
            this.launcher.getElementById("launcherButton").addEventListener("click", () => this.initWidget(), { once: true });
        }
    }

    protected createWidgetIframe() {
        const iframe = createElement(document, "iframe", [], this.sdkInstance.widgetId || "") as HTMLIFrameElement;
        iframe.setAttribute("frameBorder", "0");
        this.ui.addStyle(iframe, 'display', "none");
        iframe.title = this.i18n.t("widgetIframeTitle");
        iframe.setAttribute("data-custom", "OBIBots_widget");
        iframe.name = "OBIBots_widget";

        this.ui.setWidgetIframe(iframe);
        this.ui.setDimentions();

        iframe.addEventListener("load", () => this.widgetIframeOnLoad(iframe));
        document.body.appendChild(iframe);
    }

    protected widgetIframeOnLoad(iframe: HTMLIFrameElement) {
        this.widgetIframe = iframe;
        this.widget = iframe.contentDocument;
        this.ui.setWidget(this.widget);

        this.widget.head.innerHTML = `<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"/>`;
        const title = document.createElement("title");
        title.innerText = this.i18n.t("widgetIframeTitle");
        this.widget.head.appendChild(title);
        if (this.config.wcag_zoom) {
            this.ui.zoom();
        }

        if (this.config.custom_css) {
            this.ui.addStylesheet(`${process.env.BASE_URL}/style.css`, this.widget, () => {
                this.ui.addStylesheet(`${process.env.BASE_URL}/custom.css`, this.widget, () => {
                    this.ui.addStylesheet(this.config.custom_css, this.widget, () => {
                        this.widget.body.innerHTML = require("../templates/widget.html").toString();
                        this.widgetIframeReady();
                    }, () => {
                        console.warn(`Widget - "${this.config.custom_css}" - custom css file not found`);
                        this.widget.body.innerHTML = require("../templates/widget.html").toString();
                        this.widgetIframeReady();
                    });
                }, () => {
                    console.error("Widget - custom css not found");
                });
            }, () => {
                console.error("Widget - style css not found");
            });
        } else {
            this.ui.addStylesnode(require("../styles/style.css").toString(), this.widget);
            this.widget.body.innerHTML = require("../templates/widget.html").toString();
            this.widgetIframeReady();
        }
    }

    protected widgetIframeReady() {
        const noMenu = () => this.getMenuItems(true).length ? this.getMenuItems(true)[0] : "";
        this.widget.getElementById("header").append(
            this.hasMoreThanOneMenuItem() ? this.createMenu() : noMenu()
        );

        this.setWidgetConfig(this.config);
        this.setWidgetEvents();
        this.sessionStarted(false);

        if (this.config.notification_message) {
            const notification = createElement(this.widget, "div", "notificationMessage");
            notification.textContent = this.config.notification_message;
            this.ui.addStyle(notification, 'color', this.config.bot_message_text_color);
            this.ui.addStyle(notification, 'background-color', this.config.bot_message_background_color);
            const messageContainer = this.widget.getElementById("message-container");
            this.widget.getElementById("widget").insertBefore(notification, messageContainer);
        }

        this.widget.getElementById("message-label").textContent = this.config.placeholder_send_message ? this.config.placeholder_send_message : "Send me a message";
        this.widget.getElementById("message-label").title = this.config.placeholder_send_message ? this.config.placeholder_send_message : "Send me a message";
        this.widget.getElementById("message").setAttribute("placeholder", this.config.placeholder_send_message ? this.config.placeholder_send_message : "Send me a message");
        this.widget.getElementById("send").setAttribute("title", this.i18n.t("sendButton"));
        this.widget.getElementById("typing").setAttribute("aria-label", this.i18n.t("isTyping"));

        if (!this.config.disable_google_fonts) {
            this.insertFont();
        }
    }

    protected hasMoreThanOneMenuItem(): boolean {
        const possibleMenuItems = [
            this.config.enable_transcript,
            this.config.enable_refresh,
            this.config[this.ui.isMobile() ? "m_enable_close_button" : "enable_close_button"],
            this.config[this.ui.isMobile() ? "m_fullscreen_toggle" : "fullscreen_toggle"]
        ];
        return possibleMenuItems.filter(Boolean).length > 1;
    }

    protected getMenuItems(inHeader: boolean): HTMLElement[] {
        const items = [];
        if (this.config.enable_transcript) {
            items.push(this.createTranscriptButton(inHeader));
        }
        if (this.config.enable_refresh) {
            items.push(this.createRefreshButton(inHeader));
        }
        if (this.config[this.ui.isMobile() ? "m_enable_close_button" : "enable_close_button"]) {
            items.push(this.createCloseButton(inHeader));
        }
        if (this.config[this.ui.isMobile() ? "m_fullscreen_toggle" : "fullscreen_toggle"]) {
            items.push(this.createFullscreenToggle(inHeader));
        }
        return items;
    }

    protected setFocusOnMessageField() {
        if (!this.ui.isMobile()) {
            this.widget?.getElementById("message").focus();
        }
    }

    protected createMenu() {
        const menu = createElement(document, "div", "menu");
        const menuItemsList = createElement(document, "div", "menuItemsList");
        menu.appendChild(menuItemsList);
        this.getMenuItems(false).forEach((item: HTMLElement) =>
            menuItemsList.appendChild(item).addEventListener("click", () => this.toggleMenu(menu, button))
        );
        const messageContainer = this.widget.getElementById("message-container");
        this.widget.getElementById("widget").insertBefore(menu, messageContainer);

        const button = createElement(document, "button", [], "header-button") as HTMLButtonElement;
        button.type = "button";
        button.setAttribute("title", this.i18n.t("openMenu"));
        button.setAttribute("aria-expanded", "false");
        button.innerHTML = getSvg("menu");
        button.addEventListener("click", () => this.toggleMenu(menu, button));
        this.widgetIframe.contentWindow.addEventListener("keydown", (key) => {
            if (menu.classList.contains("open")) {
                if (key.key === "Escape") {
                    this.toggleMenu(menu, button);
                }
                if (key.key === "Tab") {
                    setTimeout(() => {
                        const el = this.widgetIframe.contentWindow.document.activeElement;
                        if (!el.closest(".menuItemsList")) {
                            this.toggleMenu(menu, button);
                        }
                    });
                }
            }
        });

        return button;
    }


    protected toggleMenu(menu: HTMLElement, button: HTMLElement) {
        menu.classList.toggle("open")
        button.setAttribute("aria-expanded", menu.classList.contains("open").toString());
        button.setAttribute("title", menu.classList.contains("open") ? this.i18n.t("closeMenu") : this.i18n.t("openMenu"));
    }

    protected createButton(
        inHeader: boolean,
        type: string,
        label: string,
        callback: () => void,
        ariaLabel?: string
    ) {
        const button = createElement(
            document,
            "button",
            null,
            inHeader ? "header-button" : null
        ) as HTMLButtonElement;
        button.type = "button";
        button.setAttribute("aria-label", ariaLabel);
        button.setAttribute("title", ariaLabel);
        button.innerHTML = inHeader ? getSvg(type) : getSvg(type) + ` ${label}`;
        button.addEventListener("click", callback);
        return button;
    }

    protected createTranscriptButton(inHeader: boolean) {
        return this.createButton(
            inHeader,
            "transcript",
            this.i18n.t("receiveTranscript"),
            () => this.sendTranscript(),
            this.i18n.t("receiveTranscript")
        );
    }

    protected createCloseButton(inHeader: boolean) {
        return this.createButton(
            inHeader,
            "close",
            this.i18n.t("close"),
            () => this._toggleWidget(new MouseEvent("click")),
            this.i18n.t("closeTheWidget")
        );
    }

    protected createFullscreenToggle(inHeader: boolean) {
        return this.createButton(
            inHeader,
            "toggle",
            this.i18n.t("toggleFullscreen"),
            () => this.ui.toggleFullscreen(),
            this.i18n.t("toggleFullscreen")
        );
    }

    protected createRefreshButton(inHeader: boolean) {
        return this.createButton(
            inHeader,
            "refresh",
            this.i18n.t("refresh"),
            () => this.handleRefresh(),
            this.i18n.t("refreshTheWidget")
        );
    }

    protected async handleRefresh() {
        try {
            this.sdkInstance.restartSession();
            this.widget.getElementById("messages").innerHTML = "";
            this.removeAlternativeReplyOption();
        } catch (e) {
            console.error("Could not restart conversation");
        }
    }

    protected sendTranscript() {
        const messageContainer = this.widget.getElementById("message-container");
        const replyBox = this.widget.getElementById("replyBox");
        const headerButton = this.widget.getElementById("header-button");

        messageContainer.classList.add("hidden");
        replyBox.classList.add("hidden");
        headerButton.classList.add("hidden");

        const formConfig = new FormModel(
            this.i18n.t("cancel"),
            [new FieldModel("email", "text", this.i18n.t("email"))],
            this.i18n.t("send"),
            this.i18n.t("receiveTranscript")
        );

        const form = createForm(
            formConfig,
            (formData: FormData) => {
                this.sdkInstance.sendTranscript({ type: "transcript", recipient_email: formData.get("email") } as {});
                close();
            },
            () => close()
        );

        const close = () => {
            messageContainer.classList.remove("hidden");
            replyBox.classList.remove("hidden");
            headerButton.classList.remove("hidden");
            form.parentElement.removeChild(form);
        };

        this.widget.getElementById("widget").insertBefore(form, messageContainer);
    }

    protected setWidgetEvents() {
        const replyBox = this.widget.getElementById("replyBox");
        replyBox.addEventListener("submit", (event: Event) => this.replySumitted(event));
        replyBox.addEventListener("invalid", ( event ) => event.preventDefault(), true );
        replyBox.addEventListener("keyup", debounce(() => this.onUserStopTyping(), 1000));
        replyBox.addEventListener("keyup", () => this.onUserStartTyping());
        if (this.config.auto_close && !this.config[this.ui.isMobile() ? "m_fullscreen" : "fullscreen"]) {
            this.widgetIframe.contentWindow.addEventListener("blur", () => {
                if (this.open) {
                    this.delayedBlur = setTimeout(() => this._toggleWidget(new MouseEvent("click")), 250)
                }
            });
        }
    }

    protected replySumitted(event: Event) {
        event.preventDefault();
        const target: HTMLFormElement = event.target as HTMLFormElement;
        const formData = new FormData(target);
        const message = { reply: formData.get("message") as string } as IExpressionData;
        const response = new BotResponseModel(message, UserType.User, "expression");
        if (message) {
            this.onSendMessage(response);
            target.reset();
        }
    }

    protected removeAttachementButton() {
        const replyBox = this.widget.getElementById("replyBox");
        const attachmentButton = replyBox.querySelector(`button[aria-label='${this.i18n.t("addAttachment")}']`);
        if (attachmentButton) {
            attachmentButton.parentElement.removeChild(attachmentButton);
        }
    }

    protected createAttachementButton() {
        const attachmentButton = this.createButton(false, "attachment", "", () => this.attachmentButtonClicked(), this.i18n.t("addAttachment"));
        const replyBox = this.widget.getElementById("replyBox");
        replyBox.appendChild(attachmentButton)
    }

    protected attachmentButtonClicked() {
        const input = document.createElement("input");
        const widget = this.widget.getElementById("widget");
        input.classList.add("hidden");
        input.setAttribute("accept", ".pdf,.png,.jpg,.jpeg,.gif,.bmp,.tiff");
        widget.prepend(input);
        input.type = "file";
        input.click();
        input.addEventListener("change", (event: Event) => this.attachmentChange(event));
    }

    protected attachmentChange(event: Event) {
        const replyBox = this.widget.getElementById("replyBox");
        const file = (event.target as HTMLInputElement).files[0];
        const reader  = new FileReader();
        reader.addEventListener("load", () => {
            this.toggleReplyBox(false);
            const attachmentPreview = createElement(document, "div", "attachmentPreview");
            const attachmentData = { reply: { url: reader.result as string, name: file.name }} as IAttachmentData;

            attachmentPreview.innerHTML = (file.size / 1024) > 2000 && file.type === "application/pdf" ? `
                <div class="no-preview">${file.name}<br />${this.i18n.t("fileToLarge")}.</div>
            ` : (createPreviewForFile(attachmentData) || `<div class="no-preview">${file.name}</div>`);

            const removePreview = () => {
                attachmentPreview.parentNode.removeChild(attachmentPreview);
                this.toggleReplyBox(true);
            };

            if (
                file.type === "application/pdf" ||
                file.type === "image/png" ||
                file.type === "image/jpeg" ||
                file.type === "image/bmp" ||
                file.type === "image/tiff" ||
                file.type === "image/gif"
            ) {
                const sendButton = this.createButton(false, "send", "", () => {
                    this.onSendAttachement({target: { files: [file] }} as unknown as Event);
                    this.addAttachmentToList(new BotResponseModel(attachmentData, UserType.User, "expression"));
                    removePreview();
                }, this.i18n.t("sendAttachment"));
                sendButton.classList.add("sendButton");
                attachmentPreview.prepend(sendButton);
                sendButton.focus();
                sendButton.blur();
            }

            const closeButton = this.createButton(false, "close", "", removePreview, this.i18n.t("removeAttachment"));
            closeButton.classList.add("closeButton");
            attachmentPreview.prepend(closeButton);

            replyBox.prepend(attachmentPreview);
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }

    }
    protected toggleReplyBox(visible: boolean) {
        const replyBox = this.widget.getElementById("replyBox");
        const input = replyBox.querySelector("input");
        const buttons = replyBox.querySelectorAll("button");
        input.classList.toggle("hidden", !visible)
        buttons.forEach((el: HTMLButtonElement) => el.classList.toggle("hidden", !visible))
    }

    protected setAvatar(avatar: string) {
        const avatarEl = this.widget.getElementById("avatar");
        if (avatar) {
            avatarEl.setAttribute("src", avatar);
        } else {
            avatarEl.parentNode.removeChild(avatarEl);
        }
    }

    protected setWidgetConfig(settings: WidgetConfigModel) {
        const setHeaderTitle = (title: string) => (this.widget.getElementById("title").textContent = title);
        const setBackgroundColor = (color: string) => this.ui.addStyle(this.widget.getElementById("message-container"), 'background-color', color);
        const setHeaderBackgroundColor = (color: string) => this.ui.addStyle(this.widget.getElementById("header"), 'background-color', color);
        const setHeaderTextColor = (color: string) => {
            this.ui.addStyle(this.widget.getElementById("header"), 'fill', color);
            this.ui.addStyle(this.widget.getElementById("header"), 'color', color);
        };
        const setHeaderSubtitle = (subtitle: string) => {
            const subEl = this.widget.getElementById("subtitle");
            if (subtitle) {
                subEl.textContent = subtitle;
            } else {
                subEl.parentNode.removeChild(subEl);
            }
        }
        const setUserStyles = (backgroundColor: string, textColor: string) =>
            setNodeStyles(UserType.User, backgroundColor, textColor);
        const setBotStyles = (backgroundColor: string, textColor: string) =>
            setNodeStyles(UserType.Bot, backgroundColor, textColor);
        const setAgentStyles = (backgroundColor: string, textColor: string) =>
            setNodeStyles(UserType.Agent, backgroundColor, textColor);
        const setButtonStyles = (backgroundColor: string, textColor: string) => 
        this.ui.addStylesnode(`.button {border-color: ${textColor}; background-color: ${backgroundColor}; color: ${textColor};} .button:focus,.button:hover {background-color: ${textColor}; color: ${backgroundColor};}`, this.widget);
        const setNodeStyles = (type: string, backgroundColor: string, textColor: string) => 
            this.ui.addStylesnode(`.attachment.${type}, .expression.${type} {background-color: ${backgroundColor}; color: ${textColor};}`, this.widget);
        const setReplyboxStyles = (background: string, color: string) => {
            this.ui.addStyle(this.widget.getElementById("replyBox"), 'background-color', background);
            this.ui.addStyle(this.widget.getElementById("replyBox"), 'color', color);
        };

        setHeaderTitle(settings.title);
        setHeaderSubtitle(settings.subtitle);
        this.setAvatar(settings.chatbot_avatar_url);
        setBackgroundColor(settings.background_color);
        setHeaderBackgroundColor(settings.header_background_color);
        setHeaderTextColor(settings.header_text_color);
        setUserStyles(settings.user_message_background_color, settings.user_message_text_color);
        setBotStyles(settings.bot_message_background_color, settings.bot_message_text_color);
        setAgentStyles(settings.bot_message_background_color, settings.bot_message_text_color);
        setButtonStyles(settings.bot_button_background_color, settings.bot_button_text_color);
        setReplyboxStyles(settings.replybox_background_color, settings.replybox_text_color);
    }

    protected _toggleWidget(event: MouseEvent) {
        event.preventDefault();
        this._toggleLauncherStyle();
        this._toggleWidgetFrame();
    }

    protected _toggleLauncherStyle() {
        if (this.launcher) {
            const target = this.launcher.getElementById("launcherButton");
            this.ui.addStyle(target, 'background-color', this.open ? this.config.launcher_color : this.config.launcher_icon_color);
            target.classList.toggle("open", !target.classList.contains("open"));
            target.title = this.open ? this.i18n.t("openTheChatbot") : this.i18n.t("closeTheChatbot");
            this.launcherIframe.title = this.open ? this.i18n.t("launcherIframeTitle") : this.i18n.t("closeTheChatbot");
            this.launcher.getElementsByTagName("title")[0].innerText = this.open ? this.i18n.t("openTheChatbot") : this.i18n.t("closeTheChatbot");
        }
    }

    protected _toggleWidgetFrame() {
        clearTimeout(this.delayedBlur)
        if (this.widgetIframe) {
            this.open = !this.open;
            this.ui.addStyle(this.widgetIframe, 'display', this.open ? "initial" : "none");
            this.widgetIframe.classList.toggle("open", this.open);
            if (!this.config.wcag_zoom) {
                const boundingClientRect = this.widgetIframe.getBoundingClientRect();
                if (!this.ui.isMobile() && boundingClientRect.top < 0) {
                    this.ui.addStyle(this.widgetIframe, 'height', `calc(${boundingClientRect.height + boundingClientRect.top}px - 16px)`);
                }
            }
            const state: StateModel = JSON.parse(SessionStorage.get(this.config.chatbot_id)) || new StateModel();
            state.isOpen = this.open;
            SessionStorage.add(this.config.chatbot_id, JSON.stringify(state));
            if (this.config.onOpen) {
                this.config.onOpen(this.open);
            }
            if (this.open && this.config.wcag_zoom) {
                this.ui.forceFullscreenIfOutOfBound();
                this.ui.optimizeForTinyScreen();
            }
        } else {
            setTimeout(() => this._toggleWidgetFrame(), 100);
        }
        this.setFocusOnMessageField()
    }

    protected hideInputForm() {
        this.widget.getElementById("replyBox").classList.add("hidden");
    }

    protected showInputForm() {
        this.setFocusOnMessageField();
        this.widget.getElementById("replyBox").classList.remove("hidden");
    }

    protected addChoices(response: BotResponseModel) {
        this.removeAlternativeReplyOption();
        this.alternativeReplyBox = new AlternativeReplyBox(this.config, (choice: IChoiceData) => this.handleChoice(choice), (response.data as IQuickReplyData).cancel_button, this.ui, this.i18n)
        const data = <IQuickReplyData>response.data;
        if (data.disable_manual_input) {
            this.hideInputForm();
        }

        switch (response.subtype) {
            case "calendar":
                this.addCalendarReplies(data);
                break;
            case "dropdown":
                this.addDropdownReplies(data);
                break;
            case "buttons":
                this.addQuickReplies(data)
                break;
            default:
                this.addQuickReplies(data);
                break;
        }
        if (!this.ui.isMobile() && this.config.wcag_zoom) {
            this.checkIfMessagesAreVisible(response);
        }
    }

    protected checkIfMessagesAreVisible(response: BotResponseModel) {
        const messageContainerRect = this.widget.getElementById("message-container").getBoundingClientRect();
        if ((this.widget.documentElement.clientHeight / 4) > messageContainerRect.height) {
            if (response.subtype !== "calendar") {
                response.subtype = "dropdown"
                this.addChoices(response)
            }
        }
    }

    protected addCalendarReplies(data: IQuickReplyData) {
        const picker = document.createElement("input");
        picker.classList.add("hidden");
        this.alternativeReplyBox.el.append(picker);

        this.widget.getElementById("widget").insertBefore(this.alternativeReplyBox.el, this.widget.getElementById("replyBox"));
        this.widget.addEventListener("messageSend", () => this.removeAlternativeReplyOption(), { once: true });
        const dates = Array.isArray(data.choices) ? data.choices.map(x => x.hasOwnProperty("value") ? x.value : x.button_text) : null;
        const extraConfigOptions = dates ? { 
            minDate: dates[0],
            maxDate: dates[dates.length - 1],
            enable: dates,
            onChange: (_: any, dateStr: string) => this.handleChoice({
                ...data.choices[dates.indexOf(dateStr)],
                entity: new EntityModel(true, data.result_entity, dateStr)
            })
        } : {
            onChange: (_: any, dateStr: string) => this.handleChoice({
                value: dateStr,
                entity: new EntityModel(true, data.result_entity, dateStr)
            } as IChoiceData)
        };
        flatpickr(picker, {
            disableMobile: true,
            inline: true,
            locale: Dutch,
            ...extraConfigOptions
        })
    }

    protected addDropdownReplies(data: IQuickReplyData) {
        const select = this.widget.createElement("select");
        this.alternativeReplyBox.el.append(select);

        const initial = this.widget.createElement("option");
        initial.textContent = this.config.dropdown_label ? this.config.dropdown_label : this.i18n.t("selectYourOption");
        initial.setAttribute("disabled", "")
        initial.setAttribute("selected", "")
        select.appendChild(initial);

        data.choices.forEach((choice: IChoiceData, i:number) => {
            const option = this.widget.createElement("option");
            option.textContent = choice.value || choice.button_text;
            option.setAttribute("title", choice.value || choice.button_text);
            option.setAttribute("aria-label", choice.value || choice.button_text);
            option.value = i.toString();
            select.appendChild(option);
        });

        select.addEventListener("change", (event: Event) => this.handleChoice(data.choices[parseInt((event.target as HTMLSelectElement).value, 10)]))

        this.alternativeReplyBox.focus();
        this.widget.getElementById("widget").insertBefore(this.alternativeReplyBox.el, this.widget.getElementById("replyBox"));
        this.widget.addEventListener("messageSend", () => this.removeAlternativeReplyOption(), { once: true });
    }

    protected addQuickReplies(data: IQuickReplyData) {
        data.choices.forEach((choice: IChoiceData) => {
            const button = this.widget.createElement("button");
            button.classList.add("button");
            button.textContent = choice.value || choice.button_text;
            button.setAttribute("title", choice.value || choice.button_text);
            button.setAttribute("aria-label", choice.value || choice.button_text);
            button.addEventListener("click", () => this.handleChoice(choice));
            this.alternativeReplyBox.el.append(button);
        });

        this.alternativeReplyBox.focus();
        this.alternativeReplyBox.labelBy(`node-${data.processed_by_node_id}`);
        this.widget.getElementById("widget").insertBefore(this.alternativeReplyBox.el, this.widget.getElementById("replyBox"));
        this.widget.addEventListener("messageSend", () => this.removeAlternativeReplyOption(), { once: true });
    }

    private handleChoice(choice: IChoiceData) {
        const responseData = new BotResponseModel(
            <IExpressionData>{ reply: choice.value || choice.button_text },
            UserType.User,
            "expression"
        );
        this.onSendMessage(responseData, choice);
        this.showInputForm();

        if (this.config.enable_redo_choices) {
            this.initChooseAgainButtons();
        }
    }

    protected removeAlternativeReplyOption() {
        this.showInputForm();
        if (this.alternativeReplyBox) {
            this.alternativeReplyBox.remove();
            delete this.alternativeReplyBox;
        }
    }

    protected initChooseAgainButtons() {
        const chooseAgainButtons = this.widget.getElementById("widget").querySelectorAll(".redo-choices-button");

        const buttonToDisplay = chooseAgainButtons[chooseAgainButtons.length - 1];
        buttonToDisplay.classList.add("show");

        Array.from(chooseAgainButtons)
            .reverse()
            .slice(1)
            .forEach((item) => item.classList.remove("show"));
    }

    protected addExpressionToList(response: BotResponseModel) {
        const message = <IExpressionData>response.data;
        const item = createElement(document, "li");
        const expression = createElement(document, "div", ["expression", response.from]);
        expression.setAttribute("tabindex", "0");
        const srOnly = createElement(document, "span", ["sr-only"]);
        srOnly.innerText = `${this.i18n.t("messageFrom")} ${this.i18n.t(response.from)}:`;
        expression.innerHTML = this.getMessageInnerHtml(message);
        expression.prepend(srOnly);

        if (response.from === UserType.Bot || response.from === UserType.Agent) {
            if (this.config.chatbot_avatar_url) expression.appendChild(this.getAvatar(response.from, this.config, message.author));
        }

        if (response.from === UserType.Agent && message.author && message.author.name) {
            const name = createElement(document, "span", "author");
            name.textContent = message.author.name;
            expression.appendChild(name);
        }

        if (message.has_choices) {
            const id = (response.data as IExpressionData).processed_by_node_id;
            const ids = this.widget.getElementById("widget").querySelectorAll(`#node-${id}`);
            ids.forEach(n => n.removeAttribute("id"));
            expression.setAttribute("id", `node-${id}`);
        }

        if (this.config.enable_redo_choices && message.has_choices) {
            expression.setAttribute("data-turn-id", response.id + "");

            const button = createElement(document, "button", "redo-choices-button");
            button.setAttribute("aria-label", this.i18n.t("redoChoice"));
            button.textContent = this.config.redo_choices_button_text;
            expression.appendChild(button);
            item.classList.add('redo');

            button.addEventListener("click", () => {
                const turnId = button.parentElement.getAttribute("data-turn-id");

                const payload = {
                    type: "backtrack",
                    turn_id: +turnId
                };

                this.sdkInstance.sendMessage(null, payload);

                // Reset view
                this.removeAlternativeReplyOption();
                button.classList.remove("show");
                item.classList.remove('redo');

                const redoButtons = Array.from(this.widget.getElementsByClassName("redo-choices-button"));

                redoButtons.forEach((redoButton: HTMLButtonElement) => redoButton.classList.remove("show"));
            });
        }

        if (response.from !== UserType.System) {
            const time = createElement(document, "span", "time");

            if (response.timestamp) {
                time.innerText = getFormattedDisplayDate(response.timestamp.toLocaleString());
            } else {
                time.innerText = getTime();
            }
            expression.appendChild(time);
        }

        item.appendChild(expression);
        this.addItemToList(item);
    }

    protected getMessageInnerHtml(message: IExpressionData) {
        if (message.rich_text_available) {
            return DOMPurify.sanitize(message.html, { ADD_ATTR: ['target'] });
        } else {
            return message.reply
                ? replaceURLWithHTMLLinks(escapeStringContent(message.reply))
                : replaceURLWithHTMLLinks(message.message);
        }
    }

    protected addAttachmentToList(response: BotResponseModel) {
        const file = <IAttachmentData>response.data;
        const item = createElement(document, "li");
        const attachment = createElement(document, "div", ["attachment", response.from]);
        attachment.innerHTML = DOMPurify.sanitize(createHtmlForFile(file, this.i18n.t("download")), { ADD_ATTR: ['target'] });
        const time = createElement(document, "span", "time");
        time.innerText = getTime();
        attachment.appendChild(time);
        if ((response.from === UserType.Bot || response.from === UserType.Agent) && this.config.chatbot_avatar_url) {
            attachment.appendChild(this.getAvatar(response.from, this.config, file.author));
        }

        if (response.from === UserType.Agent && file.author && file.author.name) {
            const name = createElement(document, "span", "author");
            name.innerText = file.author.name;
            attachment.appendChild(name);
        }
        item.appendChild(attachment);
        this.addItemToList(item);
    }

    protected addKnowledgeItemToList(response: BotResponseModel) {
        const data = <IKnowledgebaseData>response.data;
        const item = createElement(document, "li");
        const knowledge = createElement(document, "div", "kb");
        const header = createElement(document, "div", "kbHeader");
        header.innerText = data.title;
        const body = createElement(document, "div", "kbBody");
        body.innerHTML = DOMPurify.sanitize(data.body, { ADD_ATTR: ['target'] });
        const expandButton = createElement(document, "button", "expandButton");
        expandButton.setAttribute("tabindex", "0");
        expandButton.setAttribute("aria-label", `${this.i18n.t("open")} ${this.i18n.t("knowledgebaseItem")}: ${data.title}`);
        expandButton.addEventListener("click", () => {
            knowledge.classList.toggle("expanded");
            expandButton.classList.toggle("close");
            expandButton.setAttribute(
                "aria-label",
                (knowledge.classList.contains("expanded") ? this.i18n.t("close") : this.i18n.t("open")) + ` ${this.i18n.t("knowledgebaseItem")}: ${data.title}`
            );
        });

        knowledge.appendChild(header);
        knowledge.appendChild(body);
        knowledge.appendChild(expandButton);
        const srOnly = createElement(document, "span", ["sr-only"]);
        srOnly.innerText = `${this.i18n.t("messageFrom")} ${this.i18n.t(response.from)}:`;
        knowledge.prepend(srOnly);
        knowledge.setAttribute("tabindex", "0");

        if (this.config.knowledgebase_feedback) {
            knowledge.appendChild(this.createFeedbackUI(response.id));
        }

        item.appendChild(knowledge);
        this.addItemToList(item);
    }

    protected addItemToList(node: HTMLElement) {
        this.widget.getElementById("messages").appendChild(node);
        const innerContainer = this.widget.getElementById("messages");
        const scrollableContainer = this.widget.getElementById("message-container");
        setTimeout(() => (scrollableContainer.scrollTop = innerContainer.scrollHeight), 10);
    }

    protected createFeedbackUI(turnId: number) {
        const node = createElement(document, "div", "kb-feedback");
        node.innerHTML = this.i18n.t("wasThisArticleUseful");

        const yes = createElement(document, "button", "true");
        yes.setAttribute("aria-label", this.i18n.t("giveFeedback"));
        // tslint:disable-next-line:max-line-length
        yes.innerHTML = `<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24,11H21V5a3,3,0,0,0-3-3h-.4a3,3,0,0,0-2.91,2.28l-2,5.5A1.84,1.84,0,0,1,11,11H3V29H24a5,5,0,0,0,5-5V16A5,5,0,0,0,24,11ZM9,27H5V13H9Zm18-3a3,3,0,0,1-3,3H11V13a3.83,3.83,0,0,0,3.61-2.55l2-5.55,0-.12a1,1,0,0,1,1-.78H18a1,1,0,0,1,1,1v8h5a3,3,0,0,1,3,3Z"/></svg>`;
        yes.addEventListener("click", () => this.handleFeedback(node, turnId, true), { once: true });

        const no = createElement(document, "button", "false");
        no.setAttribute("aria-label", this.i18n.t("noFeedback"));
        // tslint:disable-next-line:max-line-length
        no.innerHTML = `<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M22,3H8A5,5,0,0,0,3,8v8a5,5,0,0,0,5,5h3v6a3,3,0,0,0,3,3h.4a3,3,0,0,0,2.91-2.28l2-5.5A1.84,1.84,0,0,1,21,21h8V3ZM17.39,21.55l-2,5.55,0,.12a1,1,0,0,1-1,.78H14a1,1,0,0,1-1-1V19H8a3,3,0,0,1-3-3V8A3,3,0,0,1,8,5H21V19A3.83,3.83,0,0,0,17.39,21.55ZM27,19H23V5h4Z"/></svg>`;
        no.addEventListener("click", () => this.handleFeedback(node, turnId, false), { once: true });

        node.appendChild(yes);
        node.appendChild(no);
        return node;
    }

    protected handleFeedback(node: HTMLElement, turnId: number, choice: boolean) {
        node.innerHTML = this.i18n.t("thankYou");
        setTimeout(() => node.parentNode.removeChild(node), 3000);
        this.sdkInstance.sendMessage("", {
            turn_id: turnId,
            type: "feedback",
            useful: choice
        });
    }

    protected insertFont() {
        const link = this.widget.createElement("link");
        link.href = "https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap";
        link.rel = "stylesheet";
        this.widget.head.appendChild(link);
    }
}
