import { UserType } from "../enums/user-type.enum";
import { IAttachmentData, IAuthorData } from "../models/bot-response.model";
import { WidgetConfigModel } from "../models/widget-config.model";

export const escapeStringContent = (content: string) => {
    let escapedString = content.toString().replace(/&/g, "&#38;");
    escapedString = escapedString
        .toString()
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/'/g, "&#x27;")
        .replace(/'/g, "&quot;")
        .replace(/\//g, "&#47;");
    return escapedString;
};

export const debounce = (callback: () => void, wait: number) => {
    let timeout: ReturnType<typeof setTimeout>;
    return (...args: any) => {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(context, args), wait);
    };
};

export const getTime = () => {
    const date = new Date();
    const hour = date.getHours();
    const hourString = (hour < 10 ? "0" : "") + hour;
    const min = date.getMinutes();
    const minutesString = (min < 10 ? "0" : "") + min;
    return `${hourString}:${minutesString}`;
};

export const getFormattedDisplayDate = (date: string): string => {
    let returnDate: string = new Date(date).getHours() + ':' + new Date(date).getMinutes();

    return returnDate;
};

export const getAvatar = (type: string, config: WidgetConfigModel, author?: IAuthorData) => {
    const avatar = document.createElement("img");
    avatar.classList.add("avatar");
    avatar.title = "";
    avatar.alt = author?.name || "";
    switch (type) {
        case UserType.Bot:
            avatar.src = config.chatbot_avatar_url;
            break;
        case UserType.Agent:
            avatar.src = author?.url ? author.url : "https://cloudstatic.obi4wan.com/ngbots/avatars/agent.png";
            break;
    }
    return avatar;
};

export const unescapeStringContent = (content: string) => {
    let escapedString = content.toString().replace(/&#38;/g, "&");
    escapedString = escapedString
        .toString()
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&#x27;/g, "'")
        .replace(/&amp;/g, "&")
        .replace(/&quot;/g, "'")
        .replace(/&#47;/g, "/");
    return escapedString;
};

export const replaceURLWithHTMLLinks = (expressionText: string) => {
    let text = expressionText;
    text = text.replace(/&#47;/g, "/");
    const existingMatches = text.match(/(<a).+?(<\/a>)/gi);
    if (existingMatches) {
        existingMatches.forEach((element: string, i: number) => {
            // If no target present insert target blank
            if (element.indexOf(" target=")) {
                existingMatches[i] =
                    element.slice(0, element.indexOf(">")) + " target='_blank'" + element.slice(element.indexOf(">"));
            }
            text = text.replace(element, "**existingLink" + i + "**");
        });
    }

    const newMatches = text.match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi);
    if (newMatches) {
        newMatches.forEach((element: string, i: number) => (text = text.replace(element, "**newLink" + i + "**")));
    }

    const emailMatches = text.match(
        /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g
    );
    if (emailMatches) {
        emailMatches.forEach((element: string, i: number) => (text = text.replace(element, "**emailLink" + i + "**")));
    }

    const oddMatches = text.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi
    );
    if (oddMatches) {
        oddMatches.forEach((element: string, i: number) => (text = text.replace(element, "**oddLink" + i + "**")));
    }

    const phoneMatches = text.match(
        /((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]/g
    );
    if (phoneMatches) {
        phoneMatches.forEach((element: string, i: number) => (text = text.replace(element, "**phoneLink" + i + "**")));
    }

    if (oddMatches) {
        oddMatches.forEach(
            (_element: string, i: number) =>
                (text = text.replace(
                    "**oddLink" + i + "**",
                    "<a href='//" + oddMatches[i] + "' target=_blank>" + oddMatches[i] + "</a>"
                ))
        );
    }

    if (newMatches) {
        newMatches.forEach(
            (_element: string, i: number) =>
                (text = text.replace(
                    "**newLink" + i + "**",
                    "<a href='" + newMatches[i] + "' target=_blank>" + newMatches[i] + "</a>"
                ))
        );
    }

    if (existingMatches) {
        existingMatches.forEach(
            (_element: string, i: number) => (text = text.replace("**existingLink" + i + "**", existingMatches[i]))
        );
    }

    if (phoneMatches) {
        phoneMatches.forEach(
            (_element: string, i: number) =>
                (text = text.replace(
                    "**phoneLink" + i + "**",
                    "<a href='tel:" + phoneMatches[i] + "' target=_blank>" + phoneMatches[i] + "</a>"
                ))
        );
    }

    if (emailMatches) {
        emailMatches.forEach(
            (element: string, i: number) =>
                (text = text.replace(
                    "**emailLink" + i + "**",
                    "<a href='mailto:" + emailMatches[i] + "' target=_blank>" + emailMatches[i] + "</a>"
                ))
        );
    }

    return text;
};

export const createPreviewForFile = (file: IAttachmentData) => {
    const fileName = file.reply.name;
    const fileType = fileName.substring(fileName.lastIndexOf("."));
    let html;
    switch (fileType.toLocaleLowerCase()) {
        case ".pdf":
            html = `<object type="application/pdf" data=${file.reply.url} ></object>`;
            break;
        case ".docx":
            html = `<iframe src="https://view.officeapps.live.com/op/embed.aspx?src=${file.reply.url}" frameborder="0"></iframe>`;
            break;
        case ".mp4":
            html = `<video controls class="engage-attachement"><source src="${file.reply.url}" type="video/mp4"></video>`;
            break;
        case ".jpg":
        case ".jpeg":
        case ".png":
        case ".gif":
            html = `<img src="${file.reply.url}" class="engage-attachement" />`;
            break;
        default:
            html = "";
    }
    return html;
}

export const createHtmlForFile = (file: IAttachmentData, download: string) => {
    const fileName = file.reply.name;
    const fileType = fileName.substring(fileName.lastIndexOf("."));
    let html = createPreviewForFile(file);
    switch (fileType) {
        case ".jpg":
        case ".jpeg":
        case ".png":
        case ".gif":
            break;
        default:
            html += `<br /><a href="${file.reply.url}" class="engage-attachement" target="_blank">${download} ${fileName}</a>`;
    }
    return html;
};
